import { createMuiTheme } from '@material-ui/core/styles'

import deepPurple from '@material-ui/core/colors/deepPurple'
import green from '@material-ui/core/colors/green'

export const PRIMARY_COLOR = green['A200']
export const SECONDARY_COLOR = deepPurple['A200']

const getTheme = (palette = 'light') => {
  return createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        'Google Sans',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
    },
    overrides: {
      MuiInputLabel: {
        root: {
          '&$focused': {
            color: PRIMARY_COLOR,
          },
        },
      },
    },
  })
}

export default getTheme
