const downloadFile = str => {
  const blob = new Blob([str], {type: 'text/html'})
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, 'assinatura.html')
  }
  else {
    const elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = 'assinatura.html'
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
}

export default downloadFile
