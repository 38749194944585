import React, { Component } from 'react'

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import getTheme from './utils/getTheme'
import './App.css'

import Layout from './components/Layout'

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={getTheme()}>
        <Layout />
      </MuiThemeProvider>
    )
  }
}

export default App
