import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Form, Field } from 'redux-form'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { TextField } from 'redux-form-material-ui'
import { createTextMask } from 'redux-form-input-masks'

import { formValueSelector } from 'redux-form'

import copyToClipboard from '../utils/copyToClipboard'
import downloadFile from '../utils/downloadFile'

const selector = formValueSelector('SignatureForm')

const phoneMask = createTextMask({
  pattern: '(51) 3095.82RR',
  guide: false,
  maskDefinitions: {
    R: {
      regExp: /[0-9]/,
    },
  },
})

const celphoneMask = createTextMask({
  pattern: '(51) 9.RRRR.RRRR',
  guide: false,
  maskDefinitions: {
    R: {
      regExp: /[0-9]/,
    },
  },
})

const fields = [
  { name: 'nome', lg: 6, sm: 6, xs: 12 },
  { name: 'sobrenome', lg: 6, sm: 6, xs: 12 },
  { name: 'email', lg: 12, sm: 12, xs: 12 },
  { name: 'ramal', lg: 6, sm: 6, xs: 12 },
  { name: 'celular', lg: 6, sm: 6, xs: 12 },
  { name: 'setor', lg: 12, sm: 12, xs: 12 },
]

const fieldProps = {
  nome: {
    label: 'Nome',
    component: TextField,
  },
  sobrenome: {
    label: 'Sobrenome',
    component: TextField,
  },
  email: {
    label: 'E-mail',
    component: TextField,
  },
  setor: {
    label: 'Setor',
    component: TextField,
  },
  ramal: {
    label: 'Ramal',
    component: TextField,
    ...phoneMask,
  },
  celular: {
    label: 'Celular',
    component: TextField,
    ...celphoneMask,
  },
}

const sharedProps = {
  variant: 'outlined',
}

const styles = theme => ({
  form: {
    paddingTop: theme.spacing.unit,
  },
  signature: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    backgroundColor: '#EFEFEF',
  },
  buttons: {
    marginTop: theme.spacing.unit * 1,
  },
  button: {
    paddingBottom: '0 !important',
  }
})

const SignatureForm = ({ classes, formValues, handleSubmit }) => {

  const { nome = '', sobrenome = '', email = '', setor = '', ramal = '', celular = '' } = formValues

  const nomeMarkup = <>{nome.toUpperCase()} {sobrenome.toUpperCase()}<br /></>
  const setorMarkup = setor && <><span style={{"fontWeight":"700"}}>{setor}</span><br /><br /></>
  const phoneMarkup = <>{phoneMask.format(ramal)} {celular && ` / ${celphoneMask.format(celular)}`}<br /></>
  const linkMarkup = <a target="_blank" rel="noopener noreferrer" href="https://electricservice.com.br" style={{"color":"#2b0023","textDecoration":"none"}}><span style={{"color":"#2b0023","textDecoration":"none"}}>electricservice.com.br</span></a>
  const emailMarkup = email && <a target="_blank" rel="noopener noreferrer" href={`mailto:${email}`} style={{"color":"#2b0023","textDecoration":"none"}}><span style={{"color":"#2b0023","textDecoration":"none"}}>{email}</span></a>

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit} className={classes.form}>
        <Grid
          container
          spacing={24}
          direction='row'
          justify='center'
          alignItems='center'>
          {fields.map(({ name, ...rest }) => (
            <Grid item key={name} {...rest}>
              <FormControl fullWidth>
                <Field
                  name={name}
                  {...sharedProps}
                  {...fieldProps[name]} />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Form>

      <Paper className={classes.signature}>
        <table id="signature" style={{"border":"0","borderSpacing":"0","borderCollapse":"collapse"}}>
          <tr>
            <td valign="top" style={{"padding":"0 20px 0 5px","fontFamily":"'Roboto Light'","fontSize":"13px","color":"#2b0023"}}>
              {nomeMarkup}
              {setorMarkup}
            </td>
            <td valign="top" style={{"padding":"0 0 0 20px","borderLeft":"1px solid #2b0023","fontFamily":"'Roboto Light'","fontSize":"13px","color":"#2b0023"}}>
              <table style={{"border":"0","borderSpacing":"0","borderCollapse":"collapse"}}>
                <tr>
                  <td valign="middle" style={{"padding":"0 0 1em 0"}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://electricservice.com.br"><img alt="Electric Service" height="32" src="https://electricservice.com.br/logo-email.png" border="0" /></a>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" style={{padding:0}}>
                    <table style={{"border":"0","borderSpacing":"0","borderCollapse":"collapse"}}>
                      <tr>
                        <td style={{"padding":"0","width":"2em","fontFamily":"'Roboto Light'","fontSize":"13px","color":"#2b0023"}}>T:</td>
                        <td style={{"padding":"0","fontFamily":"'Roboto Light'","fontSize":"13px","color":"#2b0023"}}>{phoneMarkup}</td>
                      </tr>
                      <tr>
                        <td style={{"padding":"0","width":"2em","fontFamily":"'Roboto Light'","fontSize":"13px","color":"#2b0023"}}>E:</td>
                        <td style={{"padding":"0","fontFamily":"'Roboto Light'","fontSize":"13px"}}>
                          {emailMarkup}
                        </td>
                      </tr>
                      <tr>
                        <td style={{"padding":"0","width":"2em","fontFamily":"'Roboto Light'","fontSize":"13px","color":"#2b0023"}}>W:</td>
                        <td style={{"padding":"0","fontFamily":"'Roboto Light'","fontSize":"13px"}}>
                          {linkMarkup}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </Paper>

      <Grid
        className={classes.buttons}
        container
        spacing={24}
        direction='row'
        justify='center'
        alignItems='center'>
        <Grid item xs={12} sm={6} className={classes.button}>
          <Button onClick={() => {
            const element = document.querySelector(`.${classes.signature}`)
            if (element && element.innerHTML) copyToClipboard(element.innerHTML)
          }} color='secondary' variant='outlined' fullWidth>Copiar</Button>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.button}>
          <Button onClick={() => {
              const element = document.querySelector(`.${classes.signature}`)
              if (element && element.innerHTML) downloadFile(element.innerHTML)
            }} color='primary' variant='outlined' fullWidth>Download</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const mapStateToPros = state => {
  const formValues = fields
    .map(f => [ f.name, selector(state, f.name) ])
    .reduce((acc, curr) => {
      acc[curr[0]] = curr[1]
      return acc
    }, {})

  return { formValues }
}

export default compose(
  connect(mapStateToPros),
  reduxForm({
    form: 'SignatureForm',
    initialValues: {
      ramal: '00',
    }
  }),
  withStyles(styles),
)(SignatureForm)
