import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import Header from './Header'
import Content from './Content'

const styles = theme => ({
  root: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: theme.spacing.unit * 3,
  },
})

const Layout = ({ classes }) => {
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <Content />
    </div>
  )
}

export default withStyles(styles)(Layout)
