import React, { Component } from 'react'
import SignatureForm from '../components/SignatureForm'

class SignatureFormContainer extends Component {
  handleSubmit = (values) => {
    console.log(values)
  }

  render() {
    return (
      <SignatureForm onSubmit={this.handleSubmit} />
    )
  }
}

export default SignatureFormContainer
