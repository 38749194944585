import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SignatureFormContainer from '../containers/SignatureFormContainer'

const styles = theme => ({
  content: {
    flexGrow: 0,
  },
  appBarSpacer: {
    minHeight: 52,
  },
})

const SignatureForm = ({ classes }) => {
  return (
    <main className={classes.contents}>
      <div className={classes.appBarSpacer} />
      <SignatureFormContainer />
    </main>
  )
}

export default withStyles(styles)(SignatureForm)
